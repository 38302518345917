<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46 46"
    xml:space="preserve"
    class="h-auto max-w-full"
  >
    <path
      fill="currentColor"
      d="M46 23C46 10.3 35.7 0 23 0C10.3 0 0 10.3 0 23M3.9 23C3.9 12.5 12.4 3.9 23 3.9C33.6 3.9 42.1 12.5 42.1 23"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1s"
        from="0 23 23"
        to="360 23 23"
        repeatCount="indefinite"
      />
    </path>
  </svg>
</template>
